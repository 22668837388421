const SuperAdminLogin = () =>
    import ("@/components/SuperAdmin/SuperAdminLogin");
const SuperAdminHome = () =>
    import ("@/components/SuperAdmin/SuperAdminHome");

const SuperMasterMain = () =>
    import ("@/components/SuperAdmin/SuperMaster/SuperMasterMain");
const AddTenant = () =>
    import ("@/components/SuperAdmin/AddTenant");    
const PushMasterToTenant = () =>
    import ("@/components/SuperAdmin/PushMasterToTenant");    
const LinkMaster = () =>
    import ("@/components/SuperAdmin/LinkMaster");    
const AddNewUserSuperAdmin = () =>
    import ("@/components/SuperAdmin/AddNewUserSuperAdmin");    
const AssignRoleToUser = () =>
    import ("@/components/SuperAdmin/AssignRoleToUser");    
const AddOrganization = () =>
    import ("@/components/SuperAdmin/AddOrganization");    
const AddLibray = () =>
    import ("@/components/SuperAdmin/AddLibray");   
//Super Master     
const AddUserType = () =>
    import ("@/components/SuperAdmin/SuperMaster/AddUserType");
const AccountStatus = () =>
    import ("@/components/SuperAdmin/SuperMaster/AccountStatus");
const AddRole = () =>
    import ("@/components/SuperAdmin/SuperMaster/AddRole");
const BookStatus = () =>
    import ("@/components/SuperAdmin/SuperMaster/BookStatus");
const LibraryConfigration = () =>
    import ("@/components/SuperAdmin/SuperMaster/LibraryConfigration");
const NotificationType = () =>
    import ("@/components/SuperAdmin/SuperMaster/NotificationType");
const NotificationMethod = () =>
    import ("@/components/SuperAdmin/SuperMaster/NotificationMethod");
const ReservationStatus = () =>
    import ("@/components/SuperAdmin/SuperMaster/ReservationStatus");
const TransactionType = () =>
    import ("@/components/SuperAdmin/SuperMaster/TransactionType");
const routes = [
    {
        path: "/superadmin",
        name: "superadmin",
        component: SuperAdminLogin,
    },
    {
        path: "/super-admin-home",
        name: "super-admin-home",
        component: SuperAdminHome,
    children: [
        {
            path: '/add-user-type-super-admin',
            name: 'add-user-type-super-admin',
            component: AddUserType,
        },
        {
            path: '/super-master-main',
            name: 'super-master-main',
            component: SuperMasterMain,
        },
        {
            path: 'add-tenant',
            name: 'add-tenant',
            component: AddTenant,
        },
        {
            path: 'push-master-to-tenant',
            name: 'push-master-to-tenant',
            component: PushMasterToTenant,
        },
        {
            path: 'link-master',
            name: 'link-master',
            component: LinkMaster,
        },
        {
            path: 'add-new-user-super-admin',
            name: 'add-new-user-super-admin',
            component: AddNewUserSuperAdmin,
        },
        {
            path: 'assign-role-to-user',
            name: 'assign-role-to-user',
            component: AssignRoleToUser,
        },
        {
            path: 'add-organization',
            name: 'add-organization',
            component: AddOrganization,
        },
        {
            path: 'add-libray',
            name: 'add-libray',
            component: AddLibray,
        },
        {
            path: 'reservation-status-super-admin',
            name: 'reservation-status-super-admin',
            component: ReservationStatus,
        },
        {
            path: 'notification-method-super-admin',
            name: 'notification-method-super-admin',
            component: NotificationMethod,
        },
        {
            path: 'notification-type-super-admin',
            name: 'notification-type-super-admin',
            component: NotificationType,
        },
        {
            path: 'library-configration-super-admin',
            name: 'library-configration-super-admin',
            component: LibraryConfigration,
        },
        {
            path: 'book-status-super-admin',
            name: 'book-status-super-admin',
            component: BookStatus,
        },
        {
            path: 'add-role-super-admin',
            name: 'add-role-super-admin',
            component: AddRole,
        },
        {
            path: 'account-status-super-admin',
            name: 'account-status-super-admin',
            component: AccountStatus,
        },
        {
            path: 'transaction-type-super-admin',
            name: 'transaction-type-super-admin',
            component: TransactionType,
        },
        
]
    }
    
];
export default routes;