<template>
  <v-app>
      <router-view />
  </v-app>
</template>
<script>


export default {
  name: "App",

  components: {
  },
methods: {

},
  data: () => ({
  })
};
</script>
<style scoped>
.header{
  /* background: cadetblue !important; */
  background: #000000c7;
}
</style>
